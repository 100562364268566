export const commonRoutes: string[] = [
  'SignIn',
];


export const loggedInRoutes: string[] = [
  'Dashboard',
  'Clusters',
  'ClusterView',
  'Nodes',
  'AccessKey'
]
